<!--
 * @Description: 店铺管理 > 资产总揽
 * @Author: zhaobin
 * @Date: 2023-02-24 10:12:38
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-14 17:36:56
-->
<script>
import { to } from 'await-to-js'
import { accountInfo, withDrawal, getBankList } from '@/api/shop'
import { yuan2fen } from '@/utils/util.js'
export default {
  data() {
    return {
      user_id: this.$route.params.id,
      dialogFormVisible: false,
      form: { trade_amount: undefined, with_draw_status: 'REAL_TIME' },
      data: {},
      bankNo: '',
      bankList: []
    }
  },
  created() {
    this.getInfo()
    this._getBankList()
  },
  methods: {
    getInfo() {
      accountInfo({ seller_id: this.user_id }).then((res) => {
        this.data = res
      })
    },
    openDialogFormVisible() {
      this.dialogFormVisible = true
      this.$refs.withdrawalForm && this.$refs.withdrawalForm.resetFields()
    },
    /**
     * @description: 银行卡列表
     * @return {*}
     */
    async _getBankList() {
      const [, res] = await to(getBankList({ seller_id: this.user_id }))
      if (res) {
        this.bankList = res.bank_list_vo
      }
    },
    /**
     * @description: 提现
     * @return {*}
     */
    withdrawal() {
      const trade_amount = yuan2fen(this.form.trade_amount)
      // if (!(trade_amount > 0)) {
      //   return this.$message.warning("请输入金额(大于0)");
      // }
      // if (trade_amount > this.data.avail_balance) {
      //   return this.$message.warning("超出可提现金额，请重新输入金额");
      // }
      if (!this.bankNo) {
        return this.$message.warning('请选择提现卡号')
      }
      // if (trade_amount > yuan2fen(50000)) {
      //   return this.$message.warning("最多可提现50000元，请重新输入金额");
      // }
      const params = {
        seller_id: this.user_id,
        order_amount: trade_amount,
        bank_bind_id: this.bankNo,
        with_draw_status: this.form.with_draw_status
      }
      withDrawal(params).then((response) => {
        this.$message.success('操作成功')
        this.dialogFormVisible = false
        this.getInfo()
      })
    }
  }
}
</script>
<template>
  <div class="app-container">
    <CustomCard>
      <SubHead> 资产总览</SubHead>
      <el-divider></el-divider>
      <el-form label-width="200px" label-suffix="：">
        <el-form-item label="公司名称">
          {{ data.sign_name }}
        </el-form-item>
        <!-- <el-form-item label="开户行">
              {{ data.bank_name }}
            </el-form-item> -->
        <el-form-item label="卡号">
          {{ data.bank_account_no }}
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="$router.push(`/shop/bank-list/${user_id}`)"
            >查看详情</el-button
          >
        </el-form-item>
        <el-form-item label="可用店铺余额（元）">
          ￥{{
            $filters.toThousands($filters.formatPrice(data.avail_balance))
          }}
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="openDialogFormVisible"
            >提现</el-button
          ></el-form-item
        >
        <el-form-item label="不可用店铺余额（元）">
          ￥
          {{ $filters.toThousands($filters.formatPrice(data.invalid_balance)) }}
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="$router.push(`/shop/settlement-details/${user_id}`)"
            >结算明细</el-button
          >
          <el-button
            type="primary"
            @click="$router.push(`/shop/cash-details/${user_id}`)"
            >提现明细</el-button
          >
        </el-form-item>
      </el-form>
    </CustomCard>
    <el-dialog title="提现" v-model="dialogFormVisible" width="500px">
      <el-form
        ref="withdrawalForm"
        :model="form"
        label-width="150px"
        label-suffix="："
      >
        <el-form-item label="选择提现到账类型" prop="trade_amount">
          <el-radio-group v-model="form.with_draw_status">
            <el-radio :label="'REAL_TIME'">实时到账</el-radio>
            <el-radio :label="'TWO_HOUR'">2小时到账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现金额" prop="trade_amount">
          <el-input-number
            v-model="form.trade_amount"
            :precision="2"
            placeholder="请输入提现金额"
          ></el-input-number>
          <div style="color: #929292">
            账户可提现余额
            {{ $filters.toThousands($filters.formatPrice(data.avail_balance)) }}
            元
          </div>
        </el-form-item>
        <el-form-item label="请选择到账银行卡">
          <el-select v-model.trim="bankNo" clearable>
            <el-option
              :key="item.bank_account_no"
              :value="item.bank_bind_id"
              :label="item.bank_account_no"
              v-for="item in bankList"
            ></el-option> </el-select
        ></el-form-item>
        <!-- <el-form-item>
                    <div style="color: #929292">两小时到账</div>
                </el-form-item> -->
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="withdrawal">提现</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
:deep(.el-radio__input.is-checked .el-radio__inner) {
  background: #0071db !important;
  border-color: #0071db !important;
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #0071db !important;
}
</style>
